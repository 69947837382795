import React from 'react';
import { StaticQuery, graphql } from 'gatsby';
import head from 'lodash.head';
import ReactMarkdown from 'react-markdown';

import Layout from '../components/layout';
import Section from '../components/section';
import Footer from '../components/footer';

import staticStyles from './static-page.module.scss';

const PrivacyPolicyPage = () => (
  <StaticQuery
    query={graphql`
      query PrivacyPolicy {
        allContentfulPrivacyPolicy {
          edges {
            node {
              backgroundColor
              navigationBackgroundColor
              seo {
                seoTitle
                seoKeywords {
                  seoKeywords
                }
                seoDescription {
                  seoDescription
                }
              }
              id
              headline
              headlineColor
              textColor
              longText {
                longText
              }
              footer {
                id
                links {
                  id
                  externalUrl
                  slug
                  displayName
                }
                logo {
                  id
                  file {
                    url
                    fileName
                    contentType
                  }
                }
              }
            }
          }
        }
      }
    `}
    render={({ allContentfulPrivacyPolicy }) => {
      const {
        backgroundColor,
        navigationBackgroundColor,
        longText,
        textColor,
        headline,
        headlineColor,
        footer,
        seo,
      } = head(allContentfulPrivacyPolicy.edges).node;

      return (
        <Layout
          navigationBackgroundColor={navigationBackgroundColor}
          seoTitle={seo.seoTitle}
          seoDescription={seo.seoDescription.seoDescription}
          seoKeywords={seo.seoDescription.seoDescription}
        >
          <Section node={headline} backgroundColor={backgroundColor}>
            <div className={staticStyles.wrapper}>
              <h1 className={staticStyles.headline} style={{ color: headlineColor }}>
                {headline}
              </h1>
              <div style={{ color: textColor }}>
                <ReactMarkdown className={staticStyles.text} source={longText.longText} />
              </div>
            </div>
          </Section>
          <Footer node={footer} />
        </Layout>
      );
    }}
  />
);

export default PrivacyPolicyPage;
